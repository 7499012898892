import '@unifly-aero/proj-common/src/styles/theme.scss';

import { Aurelia } from 'aurelia-framework';
import { bootstrap } from 'aurelia-bootstrapper';
import { configure as configureDroneweb, start as startDroneweb } from '@unifly-aero/web-droneweb/src/main';
import AppConfiguration from './config/AppConfiguration';
import {AirportDetailsViewExtension} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/airport-details-view-extension";
import {NavcanMetaDataDetails} from "@unifly-aero/proj-common/src/unifly-luciad/layers/detail-view/navcan-meta-data-details";
import {AirspaceDetailsViewExtension} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/airspace-details-view-extension";
import {UasZoneDetailsViewExtension} from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/uas-zone-details-view-extension";
import { registerInformationMenuProvider } from '@unifly-aero/web-common/src/main';
import { InformationMenuProviderNavcan } from '@unifly-aero/proj-common/src/layout/sidebar/information-menu-provider-navcan';

export function configure(aurelia: Aurelia) {
    configureDroneweb(aurelia, AppConfiguration);
    startDroneweb(aurelia);

    aurelia.container.registerSingleton(AirportDetailsViewExtension, NavcanMetaDataDetails);
    aurelia.container.registerSingleton(AirspaceDetailsViewExtension, NavcanMetaDataDetails);
    aurelia.container.registerSingleton(UasZoneDetailsViewExtension, NavcanMetaDataDetails);
    registerInformationMenuProvider(aurelia.container, InformationMenuProviderNavcan)
}

bootstrap(configure);
