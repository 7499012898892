import {inject, observable, computedFrom, PLATFORM} from "aurelia-framework";
import {Prompt} from "@unifly-aero/web-common/src/widgets/prompt";
import {I18N} from "aurelia-i18n";
import {LicenseAgreementService} from "@unifly-aero/web-common/src/services/LicenseAgreementService";
import {LicenseAgreement} from "@unifly-aero/web-common/src/model/LicenseAgreement";
import {NewInstance} from "aurelia-dependency-injection";
import {OlLegendService} from "@unifly-aero/web-common/src/unifly-luciad/legend/ol-legend-service";
import {StyleFactory} from "@unifly-aero/web-droneweb/src/droneweb/ol/StyleFactory";
import {NavCanLayerFactory} from "./NavCanLayerFactory";
import {OlMap} from "@unifly-aero/web-droneweb/src/droneweb/ol/ol-map";
import ol from "openlayers";
import {NavcanFeatureHelper} from "@unifly-aero/proj-common/src/utils/NavcanFeatureHelper";
import {LayerGroup} from "@unifly-aero/web-droneweb/src/droneweb/model/LayerGroup";
import {version as packageVersion} from '@unifly-aero/proj-droneweb/package.json';
import {NavCanLayerGroups} from "@unifly-aero/proj-common/src/model/NavCanLayerGroups";
import {AppConfig} from "@unifly-aero/web-common/src/config/AppConfig";
import { FilterFactory } from "@unifly-aero/web-droneweb/src/droneweb/ol/FilterFactory";
import { LayerDetailViews } from "@unifly-aero/web-droneweb/src/unifly-luciad/layers/detail-view/layer-detail-views";

@inject(Prompt, I18N, LicenseAgreementService, NewInstance.of(OlLegendService), StyleFactory, FilterFactory, NavCanLayerFactory, AppConfig)
export class LiveMap {
    public map: OlMap;
    public viewBounds = [-141.8,41.2,-50.9,82.0];
    public mapMinWarningResolution = 612;
    public layerGroups : LayerGroup[];
    @observable
    private selectedLayerGroup: LayerGroup;


    private redFill = 'rgba(237, 28, 36, 0.4)';
    private orangeFill = 'rgba(244, 121, 59, 0.4)';
    private yellowFill = 'rgba(252, 179, 52, 0.4)';
    private redStroke = 'rgba(237, 28, 36, 1)';
    private orangeStroke = 'rgba(244, 121, 59, 1)';
    private yellowStroke = 'rgba(252, 179, 52, 1)';

    public mapLayersPath = PLATFORM.moduleName('@unifly-aero/web-droneweb/src/droneweb/ol/ol-layers');

    private basicLegendLayers = {
        name: NavCanLayerGroups.basic,
        defaultVisible: true,
        layers: [
            {id: 'layers.control_zone', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
            {id: 'layers.delegated', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
            {id: 'layers.class_f',
                subLayers: [
                    {label: 'layers.class_f_danger_restricted', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
                    {label: 'layers.class_f_other', strokeColorLegend: this.yellowStroke, fillColorLegend: this.yellowFill}
                ]
            },
            {id: 'layers.airport',
                subLayers:[
                    {label: 'layers.airport_certified_military', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
                    {label: 'layers.airport_registered', strokeColorLegend: this.yellowStroke, fillColorLegend: this.yellowFill}
                ]
            },
            {id: 'layers.nature', strokeColorLegend: this.orangeStroke, fillColorLegend: this.orangeFill},
            {id: 'layers.notam', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
            {id: 'layers.temporary_no_fly_zone', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill}
        ]
    };
    private advancedLayer = {
        name: NavCanLayerGroups.advanced,
        defaultVisible: false,
        layers: [
            {id: 'layers.control_zone', strokeColorLegend: this.orangeStroke, fillColorLegend: this.orangeFill},
            {id: 'layers.delegated', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
            {id: 'layers.class_f',
                subLayers: [
                    {label: 'layers.class_f_danger_restricted', strokeColorLegend: this.orangeStroke, fillColorLegend: this.orangeFill},
                    {label: 'layers.class_f_other', strokeColorLegend: this.yellowStroke, fillColorLegend: this.yellowFill}
                ]
            },
            {id: 'layers.airport',
                subLayers: [
                    {label: 'layers.airport_certified_military', strokeColorLegend: this.orangeStroke, fillColorLegend: this.orangeFill},
                    {label: 'layers.airport_registered', strokeColorLegend: this.yellowStroke, fillColorLegend: this.yellowFill}
                ]
            },
            {id: 'layers.grid', strokeColorLegend: this.orangeStroke, fillColorLegend: this.orangeFill},
            {id: 'layers.nature', strokeColorLegend: this.orangeStroke, fillColorLegend: this.orangeFill},
            {id: 'layers.notam', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
            {id: 'layers.temporary_no_fly_zone', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill}
        ]
    };

    private microdroneLayer = {
        name: NavCanLayerGroups.microdrones,
        defaultVisible: false,
        layers: [
            {id: 'layers.control_zone', strokeColorLegend: this.yellowStroke, fillColorLegend: this.yellowFill},
            {id: 'layers.delegated', strokeColorLegend: this.orangeStroke, fillColorLegend: this.orangeFill},
            {id: 'layers.class_f',
                subLayers: [
                    {label: 'layers.class_f_danger_restricted', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
                    {label: 'layers.class_f_other', strokeColorLegend: this.yellowStroke, fillColorLegend: this.yellowFill}
                ]
            },
            {id: 'layers.airport',
                subLayers: [
                    {label: 'layers.airport_certified_military', strokeColorLegend: this.yellowStroke, fillColorLegend: this.yellowFill},
                    {label: 'layers.airport_registered', strokeColorLegend: this.yellowStroke, fillColorLegend: this.yellowFill}
                ]
            },
            {id: 'layers.nature', strokeColorLegend: this.orangeStroke, fillColorLegend: this.orangeFill},
            {id: 'layers.notam', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill},
            {id: 'layers.temporary_no_fly_zone', strokeColorLegend: this.redStroke, fillColorLegend: this.redFill}
        ]
    };

    constructor(
        private prompt: Prompt,
        private i18n: I18N,
        private licenseAgreementService: LicenseAgreementService,
        private legendService: OlLegendService,
        private styleFactory: StyleFactory,
        private filterFactory: FilterFactory,
        private navcanLayerFactory: NavCanLayerFactory,
        private appConfig: AppConfig,
    ) {
        this.layerGroups = [
            new LayerGroup(NavCanLayerGroups.basic, 'layergroup.basic', 'layergroup.basic_description'),
            new LayerGroup(NavCanLayerGroups.advanced, 'layergroup.advanced', 'layergroup.advanced_description'),
            new LayerGroup(NavCanLayerGroups.microdrones, 'layergroup.microdrones', 'layergroup.microdrones_selection_description'),
        ];
    }

    bind() {
        this.map.onMapReady(() => this.initMap());
    }

    selectedLayerGroupChanged() {
        this.legendService.selectGroup({name: this.selectedLayerGroup.code});

        this.map.onMapReady(() => {
            this.map.switchGroup(this.selectedLayerGroup.code);
        });
    }

    private initMap() {
        this.addLegendLayers();
        this.map.switchMapTypeTo('day-labels');
        this.addMapLayers();
    }

    private addLegendLayers() {
        this.legendService.addGroups([this.basicLegendLayers, this.advancedLayer, this.microdroneLayer]);
    }

    private addMapLayers() {
        let layerOptions = {
            maxResolution: this.mapMinWarningResolution
        };
        this.map.addGroup(NavCanLayerGroups.basic, [
            this.navcanLayerFactory.createBasicAirspaceCtrLayer('layers.control_zone', layerOptions, this.styleFactory.createStyle(this.redStroke, this.redFill, 1)),
            this.navcanLayerFactory.createDelegatedAirspaceLayer('layers.delegated', layerOptions, this.styleFactory.createStyle(this.redStroke, this.redFill, 1)),
            this.createAirspacelayer('layers.class_f', ['AIRSPACE_RESTRICTED', 'AIRSPACE_DANGER'], this.styleFactory.createStyle(this.redStroke, this.redFill, 1), layerOptions),
            this.createAirspacelayer('layers.class_f', ['AIRSPACE_OTHERAIRSPACES'], this.styleFactory.createStyle(this.yellowStroke, this.yellowFill, 1), layerOptions),
            this.createAirportLayer('layers.airport', NavcanFeatureHelper.airportTypes, NavCanLayerGroups.basic, layerOptions),
            this.navcanLayerFactory.createNatureLayer('layers.nature', null,layerOptions, this.styleFactory.createStyle(this.orangeStroke, this.orangeFill, 1)),
            this.navcanLayerFactory.createWFSLayer('layers.notam', 'notam', "geometry", this.filterFactory.notamIsActiveNowAndToday(), layerOptions, this.styleFactory.createStyle(this.redStroke, this.redFill, 1), LayerDetailViews.NOTAM),
            this.createUasZoneLayer('layers.temporary_no_fly_zone', ['TEMPORARY-NO-FLY-ZONE'], this.styleFactory.createStyle(this.redStroke, this.redFill, 1), layerOptions)
        ]);
        this.map.addGroup(NavCanLayerGroups.advanced, [
            this.navcanLayerFactory.createAdvancedAirspaceCtrLayer('layers.control_zone', layerOptions, this.styleFactory.createStyle(this.orangeStroke, this.orangeFill, 1)),
            this.navcanLayerFactory.createDelegatedAirspaceLayer('layers.delegated', layerOptions, this.styleFactory.createStyle(this.redStroke, this.redFill, 1)),
            this.createAirspacelayer('layers.class_f', ['AIRSPACE_RESTRICTED', 'AIRSPACE_DANGER'], this.styleFactory.createStyle(this.orangeStroke, this.orangeFill, 1), layerOptions),
            this.createAirspacelayer('layers.class_f', ['AIRSPACE_OTHERAIRSPACES'], this.styleFactory.createStyle(this.yellowStroke, this.yellowFill, 1), layerOptions),
            this.createAirportLayer('layers.airport', NavcanFeatureHelper.airportTypes, NavCanLayerGroups.advanced, layerOptions),
            this.navcanLayerFactory.createNatureLayer('layers.nature', null, layerOptions, this.styleFactory.createStyle(this.orangeStroke, this.orangeFill, 1)),
            this.createGridLayer(),
            this.navcanLayerFactory.createWFSLayer('layers.notam', 'notam', "geometry", this.filterFactory.notamIsActiveNowAndToday(), layerOptions, this.styleFactory.createStyle(this.redStroke, this.redFill, 1), LayerDetailViews.NOTAM),
            this.createUasZoneLayer('layers.temporary_no_fly_zone', ['TEMPORARY-NO-FLY-ZONE'], this.styleFactory.createStyle(this.redStroke, this.redFill, 1), layerOptions)
        ]);
        this.map.addGroup(NavCanLayerGroups.microdrones, [
            this.navcanLayerFactory.createBasicAirspaceCtrLayer('layers.control_zone', layerOptions, this.styleFactory.createStyle(this.yellowStroke, this.yellowFill, 1)),
            this.navcanLayerFactory.createDelegatedAirspaceLayer('layers.delegated', layerOptions, this.styleFactory.createStyle(this.orangeStroke, this.orangeFill, 1)),
            this.createAirspacelayer('layers.class_f', ['AIRSPACE_RESTRICTED', 'AIRSPACE_DANGER'], this.styleFactory.createStyle(this.redStroke, this.redFill, 1), layerOptions),
            this.createAirspacelayer('layers.class_f', ['AIRSPACE_OTHERAIRSPACES'], this.styleFactory.createStyle(this.yellowStroke, this.yellowFill, 1), layerOptions),
            this.createAirportLayer('layers.airport', NavcanFeatureHelper.airportTypes, NavCanLayerGroups.microdrones, layerOptions),
            this.navcanLayerFactory.createNatureLayer('layers.nature', null, layerOptions, this.styleFactory.createStyle(this.orangeStroke, this.orangeFill, 1)),
            this.navcanLayerFactory.createWFSLayer('layers.notam', 'notam', "geometry", this.filterFactory.notamIsActiveNowAndToday(), layerOptions, this.styleFactory.createStyle(this.redStroke, this.redFill, 1), LayerDetailViews.NOTAM),
            this.createUasZoneLayer('layers.temporary_no_fly_zone', ['TEMPORARY-NO-FLY-ZONE'], this.styleFactory.createStyle(this.redStroke, this.redFill, 1), layerOptions)
        ]);
    }

    private createGridLayer() {
        const style: ol.StyleFunction = (feature, zoomLevel) => {
            const gridColors = NavcanFeatureHelper.getGridColors(feature.getProperties().upper_limit_altitude);
            return this.styleFactory.createStyle(gridColors.stroke, gridColors.fill, zoomLevel > 700 ? 0.1 : zoomLevel > 600 ? 0.2 : zoomLevel > 170 ? 0.5 : 1);
        };
        return this.navcanLayerFactory.createGridLayer('layers.grid', null, {}, style);
    }

    private createUasZoneLayer(id: string, types: string[], style: any, layerOptions: Record<string, unknown> = {}) {
        const filter = this.filterFactory.createUasZoneFilter({types: types});
        return this.navcanLayerFactory.createUasZoneLayer(id, filter, layerOptions, style);
    }

    private createAirportLayer(id, filters,  ruleset: NavCanLayerGroups, layerOptions: Record<string, unknown> = {}) {
        const filter = this.filterFactory.createAirportFilter({types: filters});
        const style = f => {
            const colorsForAirportFeature = NavcanFeatureHelper.getAirportColors(f, ruleset);
            return this.styleFactory.createStyle(colorsForAirportFeature.strokeColor, colorsForAirportFeature.fillColor, 1)
        };

        switch(ruleset) {
            case NavCanLayerGroups.basic:
                return this.navcanLayerFactory.createBasicAirportLayer(id, filter, layerOptions, style);
            case NavCanLayerGroups.microdrones:
                return this.navcanLayerFactory.createMicrodronesAirportLayer(id, filter, layerOptions, style);
            default: // Default to Advanced layer (as before)
                return this.navcanLayerFactory.createAdvancedAirportLayer(id, filter, layerOptions, style);
        }
    }

    private createAirspacelayer(id, filters, style, layerOptions: Record<string, unknown> = {}) {
        const filter = this.filterFactory.createAirspaceFilter({categories: filters});
        return this.navcanLayerFactory.createAirspaceLayer(id, filter, layerOptions, style);
    }

    public showPopup(itemToShow: 'help' | 'disclaimer' | 'contactInfo' | 'releaseInfo' | 'privacyPolicy' | 'cookiePolicy') {
        switch (itemToShow) {
            case "cookiePolicy": this.showCookiePolicyPrompt(); break;
            case "help": this.showHelpPrompt(); break;
            case "disclaimer": this.showDisclaimerPrompt(); break;
            case "contactInfo": this.showContactInfoPrompt(); break;
            case "releaseInfo": this.showReleaseInfoPrompt(); break;
            case "privacyPolicy": this.showPrivacyPolicyPrompt(); break;
        }
    }

    private showCookiePolicyPrompt() {
        const title = this.i18n.tr("legal.droneweb.cookie_policy_title");
        const content = this.i18n.tr("legal.droneweb.cookie_policy");
        this.prompt.inform({ title: title, message: content, hasOkButton: false });
    }

    private showHelpPrompt() {
        const title = this.i18n.tr("legal.droneweb.help_title");
        const content = this.i18n.tr("legal.droneweb.help");
        this.prompt.inform({ title: title, message: content, hasOkButton: false });
    }

    private showDisclaimerPrompt() {
        const title = this.i18n.tr("legal.droneweb.disclaimer_title");
        this.licenseAgreementService.getCurrentLicenseAgreement()
            .then(lic => this.prompt.inform({ title: title, message: lic.content, hasOkButton: false }));
    }

    private showPrivacyPolicyPrompt() {
        const title = this.i18n.tr("legal.droneweb.privacy_policy_title");
        const content = this.i18n.tr("legal.droneweb.privacy_policy");
        this.prompt.inform({ title: title, message: content, hasOkButton: false });
    }

    private showContactInfoPrompt() {
        const title = this.i18n.tr("legal.droneweb.contact_info_title");
        const content = this.i18n.tr("legal.droneweb.contact_info");
        this.prompt.inform({ title: title, message: content, hasOkButton: false });
    }

    private showReleaseInfoPrompt() {
        const title = this.i18n.tr("legal.droneweb.release_info_title");
        const content = this.i18n.tr("legal.droneweb.release_info", {version: packageVersion});
        this.prompt.inform({title: title, message: content, hasOkButton: false});
    }

    get layerPanelTitle() {
        return this.i18n.tr("droneweb:map_layers");
    }

    @computedFrom()
    get minZoom() {
        return this.appConfig.mapMinZoom;
    }

    @computedFrom()
    get maxZoom() {
        return this.appConfig.mapMaxZoom;
    }

    @computedFrom()
    get defaultLocation() {
        return this.appConfig.mapDefaultLocation;
    }

    @computedFrom()
    get minWarningResolution() {
        return this.appConfig.mapMinWarningResolution;
    }

}
