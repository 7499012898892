import {PLATFORM } from 'aurelia-framework';
import { RouteConfig } from 'aurelia-router';

const customRoutes: RouteConfig[] = [
    { route: [''], name: 'droneweb', moduleId: PLATFORM.moduleName('@unifly-aero/proj-droneweb/src/droneweb/livemap/livemap-navcan'),
        nav: false, context: 'home',     title:'',auth: true, settings: { fullscreen: true }
    }
];

export const routes = customRoutes;
