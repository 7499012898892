import { AppConfig } from '@unifly-aero/web-common/src/config/AppConfig';
import DronewebAppConfig from '@unifly-aero/web-droneweb/src/config/AppConfiguration';
import NavcanCommonConfig from '@unifly-aero/proj-common/src/config/AppConfiguration';
import merge from 'lodash.merge';
import { routes } from './routes';


const AppConfiguration: AppConfig = merge<AppConfig, DeepPartial<AppConfig>, DeepPartial<AppConfig>>(NavcanCommonConfig, DronewebAppConfig, {
    routes: routes,
    clientId: 'NavcanDroneWeb',
    clientSecret: 'NavcanDroneWeb',
    mapMinZoom: 5,
    mapMaxZoom: 18,
    mapMinWarningResolution: 50,
    mapDefaultLocation: {
        lat: 45.425533,
        lon: -75.692482
    },
    i18n: {
        options: {
            ns: ['proj-droneweb', 'proj-common', 'droneweb', 'common'],
            fallbackNS: ['proj-common', 'droneweb', 'common'],
            defaultNS: 'proj-droneweb'
        }
    }
});

export default AppConfiguration;
