import {LayerFactory} from "@unifly-aero/web-droneweb/src/droneweb/ol/LayerFactory";
import {autoinject} from "aurelia-framework";
import {layer} from "openlayers";
import {AuthenticationService} from '@unifly-aero/web-common/src/services/AuthenticationService';
import {BaseMapsDataLoader} from '@unifly-aero/web-common/src/unifly-luciad/BaseMapsDataLoader';
import {FlightViewSettingsService} from '@unifly-aero/web-common/src/services/FlightViewSettingsService';
import { AppConfig } from "@unifly-aero/web-common/src/config/AppConfig";
import { LayerDetailViews } from "@unifly-aero/web-droneweb/src/unifly-luciad/layers/detail-view/layer-detail-views";

@autoinject
export class NavCanLayerFactory extends LayerFactory {

    constructor(authService: AuthenticationService,
                dataLoader: BaseMapsDataLoader,
                settingsService: FlightViewSettingsService,
                private appConfig: AppConfig) {
        super(authService, dataLoader);
    }

    public createAdvancedAirportLayer(id, filter, layerOptions = {}, styleOptions = {}) : layer.Vector {
        if (this.isDroneWeb()) {
            return super.createWFSLayer(id, 'navcan:navcan_droneweb_advanced_airport', 'geom', filter, layerOptions, styleOptions, LayerDetailViews.AIRPORT);
        }
        return super.createWFSLayer(id, 'navcan:navcan_advanced_airport', 'geom', filter, layerOptions, styleOptions, LayerDetailViews.AIRPORT);
    }

    public createBasicAirportLayer(id, filter, layerOptions = {}, styleOptions = {}) : layer.Vector {
        if (this.isDroneWeb()) {
            return super.createWFSLayer(id, 'navcan:navcan_droneweb_basic_airport', 'geom', filter, layerOptions, styleOptions, LayerDetailViews.AIRPORT);
        }
        return super.createWFSLayer(id, 'navcan:navcan_basic_airport', 'geom', filter, layerOptions, styleOptions, LayerDetailViews.AIRPORT);
    }

    public createMicrodronesAirportLayer(id, filter, layerOptions = {}, styleOptions = {}) : layer.Vector {
        if (this.isDroneWeb()) {
            return super.createWFSLayer(id, 'airport', 'geom', filter, layerOptions, styleOptions, LayerDetailViews.AIRPORT);
        }
        return super.createWFSLayer(id, 'airport', 'geom', filter, layerOptions, styleOptions, LayerDetailViews.AIRPORT);
    }

    createDelegatedAirspaceLayer(id, layerOptions = {}, styleOptions = {}) {
        return this.createWFSLayer(id, 'navcan:navcan_airspace_delegated', 'geograph', null, layerOptions, styleOptions, LayerDetailViews.AIRSPACE);
    }

    public createBasicAirspaceCtrLayer(id, layerOptions = {}, styleOptions = {}): layer.Vector {
        return super.createWFSLayer(id, 'navcan:navcan_droneweb_basic_airspace_ctr', 'geograph', null, layerOptions, styleOptions, LayerDetailViews.AIRSPACE);
    }

    public createAdvancedAirspaceCtrLayer(id, layerOptions = {}, styleOptions = {}) : layer.Vector {
        return super.createWFSLayer(id, 'navcan:navcan_droneweb_advanced_airspace_ctr', 'geograph', null, layerOptions, styleOptions, LayerDetailViews.AIRSPACE);
    }

    public createNatureLayer(id, filter, layerOptions: Record<string, unknown> = {}, styleOptions: ol.style.Style = {} as ol.style.Style) {
        return super.createWFSLayer(id, 'navcan:navcan_droneweb_nature', 'geom', null, layerOptions, styleOptions, LayerDetailViews.UAS_ZONE);
    }

    public createGridLayer(id, filter, layerOptions = {}, styleOptions = {}) : layer.Vector {
        return super.createWFSLayer(id, 'navcan:navcan_grid', 'geom', filter, layerOptions, styleOptions, LayerDetailViews.UAS_ZONE);
    }

    private isDroneWeb(): boolean {
        return this.appConfig.product === 'droneweb';
    }
}
